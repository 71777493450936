export default class Uri {
  baseURL: string = (process.env.VUE_APP_HOST ? process.env.VUE_APP_HOST : '') + process.env.VUE_APP_API;
  env: string = 'env';
  i18n: string = 'i18n';

  getUserInfo: string = 'user/info';
  updateSchema: string = 'updateschema';
  getSchema: string = 'designschema';
  color: string = 'system/color';
  user: string = 'user';
  log: string = 'log';
  error: string = 'log/error';

  bankMaterialsAll: string = 'bank-materials/all';
  bankMaterialsId(id: string): string {
    return `bank-materials/${id}`;
  }
  smsCode: string = 'session/sms-code';
  emailCode: string = 'session/email-code';
  registration: string = 'session/registration';
  login: string = 'session/login';
  loginAgent: string = 'login-agent';
  logout: string = 'session/logout';
  app: string = 'application';
  apps: string = 'applications';
  searchOrg: string = 'suggest/org';
  searchAddr: string = 'suggest/address';
  searchCountry: string = 'suggest/country';
  version: string = 'version';

  appById(id: string): string {
    return `${this.app}/${id}`;
  }
  form(id: string): string {
    return `${this.app}/${id}/form`;
  }

  // Перенесен в сервис step2 postBlankForm и getBlankForm
  blankForm(id: string): string {
    return `${this.app}/blank-form/${id}`;
  }
  blankFormStep1(id: string): string {
    return `${this.app}/blank-form/first-step/${id}`;
  }
  updateForm(id: string): string {
    return `${this.app}/${id}/update-form`;
  }
  annul(id: string): string {
    return `${this.app}/${id}/annul`;
  }
  save(id: string): string {
    return `${this.app}/update/${id}`;
  }
  status(id: string): string {
    return `${this.app}/${id}/status`;
  }
  recognitionPassport(id: string): string {
    return `${this.app}/${id ? id + '/' : ''}scanning/passport`;
  }
  participantOfApp(appId: string): string {
    return `${this.app}/${appId}/participant`;
  } //Get all the invididuals of application

  // deleteParticipant(appId:string, participantId:string):string { return `${this.app}/${appId}/participant/${participantId}`; };

  documents(id: string): string {
    return `${this.app}/${id}/client-documents`;
  }
  document(id: string): string {
    return `${this.app}/${id}/add-document`;
  }
  // Перенесен в сервис step4 fetchTableDocuments
  documentsTableStep4(id: string): string {
    return `all-documents/${id}`;
  }
  addDocRequirement(reqId: string): string {
    return `${this.app}/document-requirement/${reqId}/add-document`;
  }
  addDigitalDocument(appId: string): string {
    return `${this.app}/${appId}/add-digital-document`;
  }
  downloadR: string = 'applications/download';

  decision(id: string): string {
    return `${this.app}/${id}/decision`;
  }

  applicationSignBinary(uuids: string): string {
    return 'application/' + uuids + '/sign';
  }
  urlSigning(appId: string, signatoryId: string, accessToken: string) {
    return (fileId: string) => `signatory/${fileId}/sign/${appId}/${signatoryId}?accessToken=${accessToken}`;
  }
  downloadFileForSignatory(appId: string, signatoryId: string, accessToken: string) {
    return (fileId: string) => `/signatory/download-file/${fileId}/${appId}/${signatoryId}?accessToken=${accessToken}`;
  }
  downloadFileDocument(docId: string) {
    return 'file-download/' + docId;
  }
  generateDockLkk(appId: string): string {
    return 'document-download/' + appId;
  }
  download(id: string, docId: string): string {
    return `${this.app}/${id}/document/${docId}/download`;
  }
  // step-4
  downloadFile(fileId: string) {
    return `/application/download-document-on-step-4/${fileId}`;
  }

  nextStatus(id: string): string {
    return `${this.app}/${id}/status/next`;
  }
  meta(id: string): string {
    return `${this.app}/${id}/meta`;
  }
  // Перенесен в сервис step2 fetchBankOffices
  bankOffices: string = 'bankoffices';

  // Перенесен в сервис step2 getNewQuestionnaire
  questionnaire(id: string, idN: string): string {
    return `${this.app}/${id}/questionnaire-new?${idN}`;
  }
  validateCaptcha: string = 'captcha';

  resetClient(id: string): string {
    return `${this.app}/reset-client/${id}`;
  }

  ws: string = 'ws';
  applicationResetClient: string = 'application/reset-client';
  marketing: string = 'marketing';

  logoLoginImg: string = this.baseURL + 'image/LOGIN_LOGO_LKK';
  headerLogoImage: string = this.baseURL + 'image/UPPER_MENU_LOGO_LKK';
  navbarImg: string = this.baseURL + 'image/NAVBAR';
  bgImg: string = this.baseURL + 'image/BG_MAIN_IMAGE_LKK';
  headerImg: string = this.baseURL + 'image/HEADER';

  signatory(appId: string, signatoryId: string): string {
    return `signatory/${appId}/${signatoryId}`;
  }
  signatoryBank(appId: string, signatoryId: string): string {
    return `signatory/${appId}/bank/${signatoryId}`;
  }

}

export const uri = new Uri();
